import * as React from "react"
import { useEffect } from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import { getNoveltieAction,getNoveltiesAction } from "../../../redux/noveltiesDucks"

import {connect} from "react-redux"
//Components

const ClientSideOnlyLazy = React.lazy(() =>
  import('../../../components/Novedad/main')
)


const IndexPage = (props) => {

  const {id} = props
  const {slug} = props
  const {dispatch} = props

  const isSSR = typeof window === "undefined"

  useEffect(() => {
    dispatch(getNoveltieAction(id))
    dispatch(getNoveltiesAction())
  },[id])
  return(
    <Layout>
      <Seo title="Novedad" />
      {!isSSR && (
          <React.Suspense fallback={<div />}>
            <ClientSideOnlyLazy />
          </React.Suspense>
        )}
    </Layout>
  )
}

export default connect(state => ({
}),null)(IndexPage);